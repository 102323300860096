/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//const $ = require("jquery")

//export const onInitialClientRender = () => {
//  $(document).ready(function () {
//    console.log("The answer is don't think about it!")
//  });
//}
// Logs when the client route changes

exports.onInitialClientRender = () => {
  //console.log("render")
  //window.alert("render")
  //document.body.innerHTML+="<div id='note-service-review-floating-widgetId'></div>"
}
// trigger an immediate page refresh when an update is found

exports.onRouteUpdate = ({ location, prevLocation }) => {
  //console.log("new pathname ssr", location.pathname)
  //console.log("old pathname ssr", prevLocation ? prevLocation.pathname : null)
  const changeTitle=location.pathname.replace(/\//g, '-')
  const lastCharacter=location.pathname.substring(location.pathname.length-1);
  //console.log(changeTitle)
  if(lastCharacter==='/'){
    var newBodyClass= changeTitle.substring(1, changeTitle.length-1);
  }
  else{
    var newBodyClass= changeTitle.substring(1);
  }
  if(newBodyClass=="-"){
      newBodyClass="home";
      //var autoPlayVideo = document.getElementById("vidRef");
      //autoPlayVideo.play();
      //var x = document.getElementById("vidRef").autoplay;
      //console.log("auto"+x)
    }

  else if(newBodyClass==="complaints" ||newBodyClass==="accessibility" || newBodyClass==="treating-customers-fairly" || newBodyClass==="security" || newBodyClass==="terms-conditions" || newBodyClass==="preventing-fraud" || newBodyClass==="cookie-usage" || newBodyClass==="htbs-commitment-to-the-sme-finance-charter-pledging-our-support-for-uk-business"){
    newBodyClass="info-page-text";
  }

  document.body.className=newBodyClass
  //document.body.innerHTML+="<div id='note-service-review-floating-widgetId'></div>"

  //console.log("new");

  //document.getElementById("vidSrc").src=document.getElementById("vidSrc").src
}
//
exports.onClientEntry = () => {
  window.addEventListener('load', () => {
    const splitTitle=document.title.split("|")
    //const changeClass=splitTitle[0].trim().toLowerCase().replace(/ /g, '-')
    const changeClass=splitTitle[0].toLowerCase().replace(/ /g, '-')
  });
}
