module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HTB, the specialist bank - HTB","short_name":"HTB, the specialist bank - HTB","start_url":"/","background_color":"#041e41","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/gatsby-icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29e9bc4c03de086969781b655e5f2eeb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"HTB","trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"htb.co.uk","protocol":"https"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
